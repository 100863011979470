import { useEffect, Suspense, lazy, useState } from "react";
import ReactGA from "react-ga";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { mobileActions } from "./redux/slices/mobile/mobileSlice";
import { userActions } from "./redux/slices/user/userSlice";
import Preloader from "./components/Preloader/preloader";
import Home from "routes/Home/pages/Home/index";
import { Routes } from "./utils/routes";
import Blog from "routes/Blog";
import { Banner } from "components/banner";
import GetCaptcha from "routes/getCaptacha";
import TutorialWidget from "components/tutorialWidget";
import RegisterAsProspect from "routes/RegisterAsProspect";
const AgentRoutes = lazy(() => import("routes/AgentRoutes"));
// import AgentRoutes from "./routes/AgentRoutes";

const ComparisonPage = lazy(() => import("routes/Home/pages/Comparison Page"));
const ProspectDashboard = lazy(() => import("routes/prospectDashboard"));
const ProspectLogin = lazy(() => import("routes/prospectLogin"));
const StudentApplicationPage = lazy(
  () => import("routes/StudentApplicationForm")
);
const TermsAndConditionsPage = lazy(() => import("routes/TermsAndConditions"));
const Forgot = lazy(() => import("routes/ForgotPassword/forgot"));
const About = lazy(() => import("routes/Home/pages/AboutUs"));
const AffiliationsAndReferrals = lazy(
  () => import("routes/Home/pages/AffiliationsAndReferrals")
);
const Benefits = lazy(
  () => import("routes/Home/pages/ApplicationAndCommission")
);
const CommissionPage = lazy(() => import("routes/Home/pages/Commissions"));
const Partnerships = lazy(() => import("routes/Home/pages/Partnerships"));
const Login = lazy(() => import("routes/Login/login"));
const RequestAccess = lazy(() => import("routes/RequestAccess"));
const Reset = lazy(() => import("routes/ResetPassword/reset"));
const Verify = lazy(() => import("routes/VerifyEmail/verify"));

const CRMHome = lazy(() => import("routes/CRMLanding"));
const CRMSignup = lazy(() => import("routes/CRMSignup"));

const googleAnalyticsTrackId: any =
  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

ReactGA.initialize(googleAnalyticsTrackId);

function ScrollToTop() {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search);
  }, [pathname, search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {
  const userData = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  function screenSizeUpdate() {
    if (window.innerWidth <= 800) {
      dispatch(mobileActions.setMobile());
    } else if (window.innerWidth > 800) {
      dispatch(mobileActions.resetMobile());
    }
  }
  window.onresize = screenSizeUpdate;
  useEffect(() => {
    //listen for mobile screen size
    screenSizeUpdate();

    dispatch(userActions.fetchUser());
  }, []);

  useEffect(() => {
    let location = window.location;
    // console.log(userData);
    if (userData.isAuth && localStorage.getItem("rememberMe") === "true") {
      if (userData.details.agent && !location.pathname.startsWith("/agent")) {
        // console.log("Enter agent dashboard");
        location.replace("/agent/overview");
      } else if (
        (userData.details.student &&
          !location.pathname.startsWith("/prospects")) ||
        location.pathname === "/prospects/login"
      ) {
        // console.log("Enter student dashboard");
        location.replace("/prospects/overview");
      }
    }
  }, [userData.isAuth]);

  if (userData.loading) {
    return <Preloader />;
  }

  return (
    <div>
      {!window.location.pathname.startsWith("/student/register") ? (
        <TutorialWidget />
      ) : (
        ""
      )}
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="fallback">
              <Preloader />
            </div>
          }
        >
          <ScrollToTop />
          <Switch>
            {/* General */}
            {/* <Route exact path={Routes.home} component={Home} /> */}
            {/*  <Route exact path={Routes.blog} component={Blog} />
            <Route
              exact
              path={Routes.affiliatesAndReferrals}
              component={AffiliationsAndReferrals}
            />
            <Route
              exact
              path={Routes.comparisonPage}
              component={ComparisonPage}
            />
            <Route exact path={Routes.commissions} component={CommissionPage} /> */}
            <Route exact path={Routes.access} component={RequestAccess} />
            {/* <Route
              exact
              path={Routes.agentTermsAndConditions}
              component={TermsAndConditionsPage}
            />
            <Route exact path={Routes.about} component={About} />
            <Route exact path={Routes.partnerships} component={Partnerships} />
            <Route exact path={Routes.benefits} component={Benefits} />
            <Route exact path={Routes.crmLanding} component={CRMHome} />
            <Route exact path={Routes.crmSignup} component={CRMSignup} /> */}
            <Route exact path={Routes.captcha} component={GetCaptcha} />
            {/* <Route exact path={Routes.newagents} component={NewAgentsPage} /> */}

            {/* Auth */}
            {/* <Route exact path={Routes.signup} component={Signup} /> */}
            <Route exact path={Routes.login} component={Login} />
            <Route
              exact
              path={Routes.prospectsLogin}
              component={ProspectLogin}
            />
            <Route exact path={Routes.forgotPassword} component={Forgot} />
            <Route exact path={Routes.verify} component={Verify} />
            <Route exact path={Routes.resetPassword} component={Reset} />
            <Route
              exact
              path={Routes.studentApplicationForm}
              component={StudentApplicationPage}
            />
            <Route
              exact
              path={Routes.registerAsProspect}
              component={RegisterAsProspect}
            />
            {/* Agent */}
            {userData.isAuth &&
              userData.details.agent &&
              userData.type === "agent" && <AgentRoutes />}

            {/* Prospects */}
            {userData.isAuth &&
            userData.details.student &&
            userData.type === "student" ? (
              <Route
                exact
                path={Routes.prospectsOverview}
                component={ProspectDashboard}
              />
            ) : (
              ""
            )}

            {/* Default redirect */}
            {/* <DefaultRoute /> */}
            <Route exact path={Routes.home}>
              <Redirect to={{ pathname: Routes.login }} />
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};
export default App;
