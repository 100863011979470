export const Routes = {
  //General
  home: "/",
  commissions: "/commissions",
  affiliatesAndReferrals: "/affiliates-and-referrals",
  comparisonPage: "/comparisons",
  about: "/about",
  // newagents: "/find-partners",
  partnerships: "/sub-agents",
  benefits: "/applications",
  search: "/search",
  universities: "/universities",
  access: "/atlas/requestaccess",
  blog: "/blog",
  blogDetails: "/blog/1",
  blogDetails2: "/blog/2",
  blogDetails3: "/blog/3",
  crmLanding: "/",
  crmSignup: "/signup",

  //Auth
  signup: "/signup",
  login: "/login",
  forgotPassword: "/forgotpassword",
  verify: "/verify",
  resetPassword: "/resetpassword",
  successfulVerification: "/verification/success",

  //Student
  studentSaved: "/student/saved",
  studentMessages: "/student/messages",
  studentSettings: "/student/settings",

  //Agent
  agentProfile: "/agent/profile",
  agentMessages: "/agent/messages",
  agentOverview: "/agent/overview",
  agentSettings: "/agent/settings",
  agentPartners: "/agent/partners",
  agentPartnerships: "/agent/institutions",
  agentManagePartnership: "/agent/institutions/manage",
  agentApplications: "/agent/applications",
  agentApplicationForm: "/agent/applications/new",
  agentCommissions: "/agent/commissions",
  agentTermsAndConditions: "/agent/terms-and-conditions",
  agentCourses: "/agent/courses",
  agentProspects: "/agent/prospects",
  agentCreateProspect: "/agent/prospects/new",
  agentProspect: "/agent/prospects/:id",
  agentProspectID: (id) => `/agent/prospects/${id}`,
  agentEditProspect: "/agent/prospects/:id/edit",
  agentEditProspectID: (id) => `/agent/prospects/${id}/edit`,

  // Student
  studentApplicationForm: "/student/apply/form/:controllingAgentID",
  registerAsProspect: "/student/register/:id",

  //Payment
  agentCart: "/agent/cart",
  agentBilling: "/agent/billing-details",
  agentPay: "/agent/pay",

  //Univeristy
  universityLogin: "/university/login",
  universityForgotPassword: "/university/forgotpassword",
  universityResetPassword: "/university/resetpassword",
  universityRequest: "/university/request",
  universityProfile: "/university/profile",

  // Prospects
  prospectsLogin: "/prospects/login",
  prospectsOverview: "/prospects/overview",

  // get Captcha
  captcha: "/get-recaptcha",
};
